<template>
  <div class="quote__wrapper">
    <div class="container default-padding">
      <div class="quote__content" data-aos="flip-up">
        <div>
          <div class="title">Get A Free Quote for Industrial Project</div>
          <div class="subtitle">
            We always bring good quality services with 100% safety measures
          </div>
        </div>
        <button class="quote__button" @click="getAQuote">
          Get A Quote
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "GetAQuote",

    methods: {
      getAQuote() {
        this.$router.push("/contact-us");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .quote {
    &__wrapper {
      background-color: $secondary-color;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: white;
        font-size: 2rem;
        margin-bottom: 0.25rem;
        font-weight: 500;
      }

      .subtitle {
        color: rgba($tertiary-color, 0.6);
        font-weight: 500;
      }
    }

    &__button {
      padding: 1rem 2rem;
      border: none;
      border-radius: 0.25rem;
      font-size: 1rem;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .quote {
      &__content {
        flex-direction: column;
        align-items: flex-start;

        .subtitle {
          margin-bottom: 1.5rem;
        }
      }

      &__button {
        font-size: 14px;
      }
    }
  }
</style>
