<template>
  <div style="width:100%" data-aos="fade-up">
    <div class="container section-padding">
      <div class="section-padding">
        <div class="text-center" style="margin-bottom: 50px">
          <div class="primary-heading">
            {{ labels.LBL_VALUABLE_CLIENTS }}
          </div>
        </div>

        <div v-if="!loading">
          <slider
            v-if="allClients && allClients.length"
            ref="slider"
            :options="options"
          >
            <slideritem
              v-for="(item, index) in allClients"
              :key="index"
              class="company"
            >
              <img :src="item.logo_url" alt="" class="company-logo" />
            </slideritem>
          </slider>
          <div v-else class="text-center caption">
            {{ labels.PH_CLIENTS_NF }}
          </div>
        </div>
        <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { slider, slideritem } from "vue-concise-slider";
  import { labels } from "@/common";
  import { mapState } from "vuex";

  export default {
    name: "HorizontalClientsSlider",

    components: {
      slider,
      slideritem,
    },

    data() {
      return {
        labels,

        options: {
          currentPage: 0,
          thresholdDistance: 500,
          thresholdTime: 500,
          autoplay: 2000,
          loop: true,
          loopedSlides: 6,
          slidesToScroll: 1,
          pagination: false,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        clients: (state) => state.clients.clients,
      }),

      allClients() {
        return this.clients && this.clients.data;
      },
    },

    created() {
      this.loadClients();
    },

    methods: {
      loadClients() {
        this.$store.dispatch("clients/getAllClients");
      },
    },
  };
</script>

<style scoped>
  .clients {
    padding: 3rem 0;
  }
  .company {
    width: 25%;
    height: 100px;
  }

  .company-logo {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: contain;
    z-index: -1;
  }

  @media only screen and (max-device-width: 768px) {
    .company {
      width: 50%;
    }
  }
</style>
